import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { DialogService } from '../../../services/dialog.service';
import { CallService } from '../../../services/call.service';
import { FlashMessageService } from '../../../services/flash-message.service';
import { LoginService } from '../../../services/login.service';

import { Call } from 'src/app/structures/call';

import * as FileSaver from 'file-saver';

/**
 *  composant gérant l'affichage des calls dans le menu de gauche
 */
@Component({
    selector: 'app-nav-entry',
    templateUrl: './nav-entry.component.html',
    styleUrls: ['./nav-entry.component.scss'],
    standalone: false
})
export class NavEntryComponent implements OnInit, OnDestroy {
    /**
     * @param {any} call le call en entry.
     * @param {number} selectedCall l'id du call selectionne en input.
     * @param {string} type le tyoe du call.
     * @param {boolean} isStarted verifie si le call a demarre.
     * @param {boolean} mailIsSent verifie si le mail est envoye.
     */
    @Input() call: any;
    @Input() selectedCall: number;
    @Input() type: string;

    isStarted = false;
    mailIsSent = false;

    subscriptions = new Subscription();

    constructor(
        private callService: CallService,
        private dialogService: DialogService,
        private flashMessageService: FlashMessageService,
        private loginService: LoginService
    ) {}

    ngOnInit() {
        this.call.starttime = new Date(this.call.starttime);
        this.call.endtime = new Date(this.call.starttime);
        this.call.endtime.setSeconds(this.call.endtime.getSeconds() + this.call.duration);
        this.isStarted = this.type !== 'history' && new Date().valueOf() > this.call.starttime;
        this.call.starttime = new Date(this.call.starttime);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    /**
     *  indique si le call actuel est sélectionné ou non.
     * @returns {Boolean} true si le call actuel est sélectionné, faux dans le cas contraire.
     */
    isCallSelected(): boolean {
        return this.call.id === this.selectedCall;
    }

    downloadVideo(video_url: string) {
        FileSaver.saveAs(video_url);
    }

    getCallNotificationsIcon(): string {
        if (this.call.isMuted) {
            return 'icon-notifications';
        }
        return 'icon-notifications-barree';
    }

    getNotificationsIconTooltip(): string {
        if (this.call.isMuted) {
            return 'Activer les notifications';
        }
        return 'Désactiver les notifications';
    }

    canExportPolls() {
        if (this.loginService.getUser()) {
            return (
                this.call.events === 'processed' &&
                this.call.haspolls &&
                (this.loginService.getUser().roles.nationalAdmin ||
                    this.loginService.getUser().roles.localAdmin ||
                    this.isAuthor() ||
                    this.call.moderator_ids.indexOf(this.loginService.getUser().id.toString()) > -1)
            );
        }
    }

    isAuthor() {
        if (this.loginService.getUser()) {
            return this.loginService.getUser().id === this.call.ownerid;
        }
        return false;
    }

    canOpenEditCall() {
        return (
            this.type !== 'history' &&
            (this.loginService.getUser().roles.localAdmin ||
                this.loginService.getUser().roles.nationalAdmin ||
                this.loginService.getUser().id === this.call.ownerid ||
                this.loginService.isCallModerator(this.call.id))
        );
    }

    exportPolls() {
        this.callService.exportPolls(this.call).subscribe(null);
    }

    toggleCallNotifications($event: Event, call: Call): void {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.callService.toggleMuteCall(call).subscribe((data) => {
                this.call.isMuted = !this.call.isMuted;
                this.flashMessageService.flash(
                    `les notifications du call ${call.name} ont été ${
                        this.call.isMuted ? 'désactivées' : 'activées'
                    }`
                );
            })
        );
    }

    openDuplicateCallDialog($event: Event) {
        $event.stopPropagation();
        this.dialogService.openCreateCallDialog(this.call.id, undefined, undefined, true);
    }

    /**
     *  ouvre la fenêtre d'édition du call.
     * @param {Event} $event l'event Javascript émis lors du clic.
     */
    openEditCallDialog($event: Event) {
        $event.stopPropagation();
        this.dialogService.openCreateCallDialog(this.call.id);
    }

    /**
     *  ouvre la fenêtre de suppression de call.
     * @param {Event} $event l'event Javascript émis lors du clic.
     */
    openDeleteCallDialog($event: Event) {
        $event.stopPropagation();
        this.dialogService.openDeleteCallDialog(this.call.id);
    }

    /**
     *  appelle le web service de rappel d'un call.
     */
    remindCall() {
        this.mailIsSent = true;
        this.subscriptions.add(
            this.callService.remindCall(this.call.id).subscribe(
                (data) => {
                    this.dialogService.openSuccessDialog('Le mail a bien été envoyé.');
                },
                (error: HttpErrorResponse) => {
                    this.dialogService.openErrorDialog(error.error.userMessage);
                }
            )
        );
    }

    /**
     *  affiche ou non l'icone poubelle.
     * @returns {boolean} true si on doit l'afficher.
     */
    displayBin(): boolean {
        return this.call.starttime.valueOf() - new Date().valueOf() > 900000 || !this.isStarted;
    }
}
