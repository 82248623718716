<div
    #label
    tabindex="0"
    class="label {{ customClass }}"
    (focus)="handleFocusEvent($event)"
    (blur)="handleBlur()"
    (mousedown)="toggleDropdown($event)"
    [ngClass]="{ opened: opened, disabled: disabled }"
    [ngStyle]="{ width: labelWidth, color: labelColor, 'min-width': labelMinWidth }"
>
    <div class="left-container">
        <div class="icon" [ngClass]="getLabelIcon()" *ngIf="getLabelIcon()"></div>
        <div class="legend" [ngStyle]="{ color: getLabelColor() }">{{ getLabelText() }}</div>
    </div>
    <div>

        <span class="selection-count" *ngIf="getSelectionCount() !== 0 && multiselect"
        >({{ getSelectionCount() }})</span
        >
        <div class="carrot">
            <span class="icon-deroulant"></span>
        </div>
    </div>
</div>
<div
    #dropdownContent
    class="dropdown-content {{ customClassUpdated }}"
    [ngStyle]="{ 'width.px': uniformWidth ? label.offsetWidth : '*' }"
    [@slideToggle]="opened ? 'open' : 'closed'"
>
    <div class="header" *ngIf="headers">
        <div class="filters">
            <div class="test">
                <div class="selected-count" *ngIf="headers?.count">
                    <b>{{ getSelectionCount() }}</b> / {{ getTotalItemCount() }} sélectionnés
                </div>
                <div class="search-container" *ngIf="headers?.search">
                    <input
                        #search
                        class="search-input"
                        category="text"
                        placeholder="Chercher..."
                        (blur)="closeDropdown()"
                        [(ngModel)]="searchTerm"
                        (ngModelChange)="refreshFilterSearch()"
                        (keyup)="handleKeyUp($event)"
                    />
                    <div class="icon-search" *ngIf="!searchTerm"></div>
                    <div
                        class="icon-close"
                        *ngIf="searchTerm"
                        (click)="resetSearchTerm($event)"
                    ></div>
                </div>
                <input
                    #search
                    *ngIf="!headers?.search"
                    class="search-input hidden"
                    type="text"
                    (keyup)="handleKeyUp($event)"
                />
            </div>
            <div
                class="select-all"
                *ngIf="headers?.selectAll && multiselect && !isAllSelected()"
                (click)="selectAll()"
            >
                Tout sélectionner
            </div>
            <div
                class="select-all"
                *ngIf="headers?.selectAll && multiselect && isAllSelected()"
                (click)="unselectAll()"
            >
                Tout désélectionner
            </div>
        </div>
    </div>
    <div class="scrollbar-container" [ngStyle]="{ 'max-height.px': maxHeight }">
        <div class="list">
            <lib-element
                *ngFor="let item of filteredItems; let i = index"
                [(item)]="filteredItems[i]"
                [multiselect]="multiselect"
                [design]="design"
                [maxWidth]="maxWidth"
                (itemSelectedInSingleselect)="itemSelectedInSingleselect(item)"
                (itemSelectedInMultiselect)="itemSelectedInMultiselect(item)"
            ></lib-element>
        </div>
    </div>
</div>
