import { Component } from '@angular/core';

import { FlashMessageService } from '../../services/flash-message.service';

@Component({
    selector: 'app-flash-message',
    templateUrl: './flash-message.component.html',
    styleUrls: ['./flash-message.component.scss'],
    standalone: false
})
export class FlashMessageComponent {
    constructor(private flashMessageService: FlashMessageService) {}

    getFlashMessage() {
        return this.flashMessageService.getFlashMessage();
    }

    isFlashing() {
        // return true;
        return this.flashMessageService.isFlashing();
    }

    stopFlashing() {
        this.flashMessageService.stopFlashing();
    }
}
