import { Directive, Input, ComponentRef, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { TooltipComponent } from './tooltip/tooltip.component';
import {
    OverlayRef,
    Overlay,
    OverlayPositionBuilder,
    ConnectionPositionPair,
    ConnectedPosition
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

@Directive({
    selector: '[tooltip]',
    standalone: false
})
export class TooltipDirective implements OnInit, OnDestroy {
    @Input('tooltip') text = '';
    @Input() tooltipColor = '#000';
    @Input() tooltipTextColor = '#fff';
    @Input() tooltipPosition = 'left'; // above | below | right | left | above-right | below-right
    //  if false : no arrow is displayed
    @Input() hasArrow = true;
    tooltipPortal!: ComponentPortal<TooltipComponent>;

    private overlayRef!: OverlayRef;

    constructor(
        private overlayPositionBuilder: OverlayPositionBuilder,
        private elementRef: ElementRef,
        private overlay: Overlay
    ) {}

    @HostListener('mouseover') show() {
        if (!this.overlayRef.hasAttached()) {
            // Create tooltip portal
            this.tooltipPortal = new ComponentPortal(TooltipComponent);

            // Attach tooltip portal to overlay
            const tooltipRef: ComponentRef<TooltipComponent> = this.overlayRef.attach(this.tooltipPortal);
            // Pass content to tooltip component instance
            tooltipRef.instance.text = this.text;
            tooltipRef.instance.tooltipColor = this.tooltipColor;
            tooltipRef.instance.hasArrow = this.hasArrow;
            tooltipRef.instance.tooltipPosition = this.tooltipPosition;
            tooltipRef.instance.tooltipTextColor = this.tooltipTextColor;
        }
    }

    @HostListener('click')
    @HostListener('mouseout')
    hide() {
        this.overlayRef.detach();
    }

    setPositions(): ConnectedPosition[] {
        const top: ConnectedPosition = {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom',
            offsetY: -8,
            panelClass: 'above-panel-tooltip'
        };
        const bottom: ConnectedPosition = {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
            offsetY: 8,
            panelClass: 'below-panel-tooltip'
        };
        const left: ConnectedPosition = {
            originX: 'start',
            originY: 'center',
            overlayX: 'end',
            overlayY: 'center',
            offsetX: -8,
            panelClass: 'left-panel-tooltip'
        };
        const right: ConnectedPosition = {
            originX: 'end',
            originY: 'center',
            overlayX: 'start',
            overlayY: 'center',
            offsetX: 8,
            panelClass: 'right-panel-tooltip'
        };
        const belowRight: ConnectedPosition = {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
            offsetY: 4,
            panelClass: 'below-right-panel-tooltip'
        };
        const belowLeft: ConnectedPosition = {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
            offsetY: 4,
            panelClass: 'below-left-panel-tooltip'
        };
        const aboveRight: ConnectedPosition = {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
            offsetY: -4,
            panelClass: 'above-right-panel-tooltip'
        };
        const aboveLeft: ConnectedPosition = {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
            offsetY: -4,
            panelClass: 'above-left-panel-tooltip'
        };
        switch (this.tooltipPosition) {
            case 'above':
                return [top, bottom];
                break;
            case 'below':
                return [bottom, top];
                break;
            case 'left':
                return [left, right];
                break;
            case 'right':
                return [right, left];
                break;
            case 'below-right':
                return [belowRight, belowLeft, aboveRight, aboveLeft];
                break;
            case 'below-left':
                return [belowLeft, belowRight, aboveLeft, aboveRight];
                break;
            case 'above-right':
                return [aboveRight, aboveLeft, belowRight, belowLeft];
                break;
            case 'above-left':
                return [aboveLeft, aboveRight, belowLeft, belowRight];
                break;
            default:
                return [top, bottom, aboveLeft, aboveRight, belowLeft, belowRight];
                break;
        }
    }

    ngOnInit() {
        const positions = this.setPositions();
        const positionStrategy = this.overlayPositionBuilder
            // Create position attached to the elementRef
            .flexibleConnectedTo(this.elementRef)
            // Describe how to connect overlay to the elementRef
            // Means, attach overlay's center bottom point to the
            // top center point of the elementRef.
            .withPositions(positions)
            .withTransformOriginOn('.tooltip-container');
        this.overlayRef = this.overlay.create({ positionStrategy });
    }

    ngOnDestroy() {
        this.overlayRef.detach();
        this.overlayRef.dispose();
    }
}
