import {
    Component,
    OnInit,
    EventEmitter,
    Output,
    OnDestroy,
    ViewChild,
    ElementRef
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

import { DialogService } from '../../services/dialog.service';
import { CallService } from '../../services/call.service';
import { LoginService } from '../../services/login.service';
import { Subscription } from 'rxjs';
import { HistoryService } from 'src/app/services/history.service';
import { Call } from 'src/app/structures/call';
import { slideToggle } from 'src/app/animations/slide-toggle';

/**
 *  composant gérant l'affichage du menu de gauche
 */
@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss'],
    animations: [slideToggle],
    standalone: false
})
export class NavComponent implements OnInit, OnDestroy {
    /**
     * @param {EventEmitter} select event emetant la selection d'un call avec les infos du dit call.
     * @param {Array<Call>} authorsCalls liste des auteurs d'un call.
     * @param {Array<Call>} membersCalls liste des membres d'un call.
     * @param {Array<Call>} historicCalls historique des calls passés.
     * @param {number} selectedCall l'id du call selectionne.
     * @param {number} startedCallsCount nombre de calls que l'user a cree.
     * @param {number} memberCallsCount nombre de calls dont l'user est membre.
     * @param {number} totalCallsCount nomnbre total des calls de l'user.
     * @param {boolean} isClosedAuthorCalls verifie si oui ou non la categorie "Mes calls" est ouverte ou non.
     * @param {boolean} isClosedMemberCalls verifie si oui ou non la categorie "Autres calls" est ouverte ou non.
     * @param {boolean} isClosedHistoricCalls verifie si oui ou non la categorie "Historique" est ouverte ou non.
     */
    @Output() select: EventEmitter<any> = new EventEmitter();
    @Output() resize: EventEmitter<boolean> = new EventEmitter();

    subscriptions = new Subscription();

    search: string;
    activeSearch: boolean;
    @ViewChild('searchElement') searchElement: ElementRef;

    authorsCalls: Array<Call>;
    membersCalls: Array<Call>;
    historicCalls: Array<Call>;

    selectedCall: number;

    startedCallsCount: number;
    memberCallsCount: number;
    totalCallsCount: number;

    isClosedAuthorCalls = false;
    isClosedMemberCalls = false;
    isClosedHistoricCalls = false;

    reduced = false;

    datesFilterOpened = false;
    today = new Date();

    isHistoryLoading = false;

    getHistoryParams = {
        with_recording: false,
        startdate: new Date(),
        enddate: new Date(),
        limit: 30,
        offset: 0
    };

    constructor(
        private dialogService: DialogService,
        private callService: CallService,
        private loginService: LoginService,
        private historyService: HistoryService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.getHistoryParams.startdate.setMonth(this.getHistoryParams.startdate.getMonth() - 3);
        this.getHistoryParams.startdate.setHours(0, 0, 0, 0);
        this.getHistoryParams.enddate.setHours(23, 59, 59, 0);
    }

    refreshDataAndHistory() {
        this.refreshData();
        this.updateHistory();
        if (this.activeSearch && !this.search) {
            this.toggleActiveSearch();
        }
    }

    /**
     *  rafraichit la liste des calls de l'utilisateur.
     */
    refreshData(): void {
        this.subscriptions.add(
            this.callService.getCalls(this.search).subscribe(
                (data: any) => {
                    this.authorsCalls = data.mine;
                    this.startedCallsCount = data.mine.length;

                    this.membersCalls = data.member;
                    this.memberCallsCount = data.member.length;

                    this.totalCallsCount = this.memberCallsCount + this.startedCallsCount;

                    // this.toggleMemberCalls();
                },
                (error: HttpErrorResponse) => {
                    if (error.error.errorCode !== 'USER_NOT_AUTH') {
                        this.dialogService.openErrorDialog(error.error.userMessage);
                    }
                }
            )
        );
    }

    /**
     *  déplie/replie la liste des calls dont l'utilisateur est auteur.
     */
    toggleAuthorCalls(): void {
        this.isClosedAuthorCalls = !this.isClosedAuthorCalls;
        // $('#author-calls-container').slideToggle(300);
        if (!this.isClosedAuthorCalls && !this.isClosedMemberCalls) {
            this.toggleMemberCalls();
        }
        if (!this.isClosedAuthorCalls && !this.isClosedHistoricCalls) {
            this.toggleHistoricCalls();
        }
    }

    /**
     *  déplie/replie la liste des calls dont l'utilisateur est membre.
     */
    toggleMemberCalls(): void {
        this.isClosedMemberCalls = !this.isClosedMemberCalls;
        // $('#member-calls-container').slideToggle(300);
        if (!this.isClosedMemberCalls && !this.isClosedAuthorCalls) {
            this.toggleAuthorCalls();
        }
        if (!this.isClosedMemberCalls && !this.isClosedHistoricCalls) {
            this.toggleHistoricCalls();
        }
    }

    /**
     *  déplie/replie la liste de l'historique des calls.
     */
    toggleHistoricCalls(): void {
        this.isClosedHistoricCalls = !this.isClosedHistoricCalls;
        // $('#historic-calls-container').slideToggle(300);
        if (!this.isClosedHistoricCalls && !this.isClosedAuthorCalls) {
            this.toggleAuthorCalls();
        }
        if (!this.isClosedHistoricCalls && !this.isClosedMemberCalls) {
            this.toggleMemberCalls();
        }
    }

    toggleReduce(): void {
        this.reduced = !this.reduced;
        this.resize.emit(this.reduced);
    }

    /**
     * active/désactive l'option "contient un enregistrement", remet à 0 l'offset et effectue la requête d'historique avec les nouveaux paramètres
     */
    toggleHistoryRecordingSelector(): void {
        this.getHistoryParams.with_recording = !this.getHistoryParams.with_recording;
        this.resetHistoryOffset();
        this.updateHistory();
    }

    /**
     * ouvre/ferme la dropdown de sélection de dates pour l'historique
     */
    toggleHistoryDatesSelector(): void {
        this.datesFilterOpened = !this.datesFilterOpened;
    }

    /**
     * effectue le requête d'historique de calls avec les paramètres actuels et un offset réinitialisé.
     */
    updateHistory(): void {
        this.isHistoryLoading = true;
        this.historicCalls = [];
        this.resetHistoryOffset();
        this.subscriptions.add(
            this.historyService.getHistory(this.getHistoryParams, this.search).subscribe(
                (data: Call[]) => {
                    this.historicCalls = data;
                    this.isHistoryLoading = false;
                },
                (error: HttpErrorResponse) => {
                    this.isHistoryLoading = false;
                    this.dialogService.openErrorDialog("Une erreur s'est produite");
                }
            )
        );
    }

    /**
     * réinitialise l'offset de recherche dans l'historique des calls
     */
    resetHistoryOffset(): void {
        this.getHistoryParams.offset = 0;
    }

    /**
     * charge les données suivantes de l'historique pour l'infinite scroll
     */
    nextPageHistory(): void {
        this.getHistoryParams.offset += 30;
        this.subscriptions.add(
            this.historyService
                .getHistory(this.getHistoryParams, this.search)
                .subscribe((data: Call[]) => {
                    this.historicCalls.push(...data);
                })
        );
    }

    /**
     *  sélectionne un call à afficher dans la vue principal.
     * @param {number} idCall l'id du call selectionne.
     * @param {boolean} history si il est dans l'historique ou non.
     */
    selectCall(idCall: number, history: boolean): void {
        this.selectedCall = idCall;
        this.select.emit({ id: idCall, history: history });
    }

    /**
     *  ouvre la fenêtre de création de call.
     */
    openCreateCallDialog(): void {
        this.dialogService.openCreateCallDialog();
    }

    /**
     *  ouvre la fenêtre d'enregistrement à un call.
     */
    openSubscribeCallDialog(): void {
        this.dialogService.openSubscribeCallDialog();
    }

    /**
     *  ouvre la fenêtre d'enregistrement à un call.
     */
    openExportCallDialog(): void {
        this.dialogService.openExportCallDialog();
    }

    /**
     *  indique si la bouton permettant d'ouvrir la fenetre de création de call doit être affiché.
     * @returns {boolean} True si le bouton doit être affiché, faux dans le cas contraire.
     */
    showCreateButton(): boolean {
        return !this.loginService.isLearner();
    }

    /**
     *  indique si la bouton permettant d'ouvrir la fenetre d'enregistrement à un call doit être affiché.
     * @returns {boolean} True si le bouton doit être affiché, faux dans le cas contraire.
     */
    showSubscribeButton(): boolean {
        if (this.loginService.getUser()) {
            return (
                this.loginService.getUser().roles.nationalAdmin ||
                this.loginService.getUser().roles.localAdmin ||
                this.loginService.getUser().roles.internalTeacher ||
                this.loginService.getUser().roles.externalTeacher ||
                this.loginService.getUser().roles.siteTeahcer
            );
        }
    }

    /**
     *  indique si la bouton permettant d'ouvrir la fenetre d'enregistrement à un call doit être affiché.
     * @returns {boolean} True si le bouton doit être affiché, faux dans le cas contraire.
     */
    showExportButton(): boolean {
        if (this.loginService.getUser()) {
            return this.loginService.isAdmin() || this.loginService.getUser().roles.internalTeacher;
        }
    }

    checkConfig() {
        if (window.location.href.indexOf('dev.call.easi-training') > -1) {
            window.open('https://dev.call.easi-training.fr/checkconfig.html');
        } else if (window.location.href.indexOf('pilot.call.easi-training') > -1) {
            window.open('https://pilot.call.easi-training.fr/checkconfig.html');
        } else if (window.location.href.indexOf('call.easi-training') > -1) {
            window.open('https://call.easi-training.fr/checkconfig.html');
        } else if (window.location.href.indexOf('localhost') > -1) {
            window.open(window.location.href.split('#')[0] + 'checkconfig.html');
        }
    }

    toggleActiveSearch() {
        if (!this.activeSearch) {
            this.activeSearch = true;
            setTimeout(() => {
                this.searchElement.nativeElement.focus();
            });
        } else {
            setTimeout(() => {
                this.activeSearch = false;
            }, 100);
        }
    }

    isSearchEmpty() {
        if (this.search) {
            return this.search.length === 0;
        }
        return true;
    }

    resetSearch($event: Event): void {
        $event.preventDefault();
        $event.stopImmediatePropagation();
        this.search = '';
        this.activeSearch = false;
        this.refreshDataAndHistory();
    }

    ngOnInit() {
        this.subscriptions.add(
            this.route.queryParams.subscribe((params) => {
                if (params['new']) {
                    if (params['user']) {
                        this.dialogService.openCreateCallDialog(null, null, +params['user']);
                    } else if (params['group']) {
                        this.dialogService.openCreateCallDialog(null, +params['group'], null);
                    } else {
                        this.dialogService.openCreateCallDialog();
                    }
                } else if (params['subscribe']) {
                    if (params['call']) {
                        this.dialogService.openSubscribeCallDialog(+params['call']);
                    }
                } else if (params['shared']) {
                    if (params['type'] && params['id']) {
                        this.dialogService
                            .openSharedCallDialog(params['type'], params['id'])
                            .subscribe((data: any) => {
                                if (data && data.id) {
                                    this.selectCall(data.id, false);
                                    this.router.navigate(['', data.id]);
                                }
                            });
                    }
                }
            })
        );

        this.search = '';
        this.totalCallsCount = 0;
        this.refreshData();
        this.toggleMemberCalls();
        this.toggleHistoricCalls();

        this.subscriptions.add(
            this.callService.refreshListCalls.subscribe(
                () => {
                    this.refreshData();
                    if (this.isClosedAuthorCalls) {
                        this.toggleAuthorCalls();
                    }
                },
                (error: HttpErrorResponse) => {
                    this.dialogService.openErrorDialog(error.error.userMessage);
                }
            )
        );

        this.subscriptions.add(
            this.historyService
                .getHistory(this.getHistoryParams, this.search)
                .subscribe((data: Call[]) => {
                    this.historicCalls = data;
                })
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
