import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription, Subject } from 'rxjs';

import { UsersService } from '../../services/users.service';
import { CallService } from '../../services/call.service';
import { LoginService } from '../../services/login.service';

import { Group } from '../../structures/group';
import { User } from '../../structures/user';

import moment from 'moment';

/**
 *  composant gérant l'affichage de la fenêtre de création de call
 */
@Component({
    selector: 'app-create-call',
    templateUrl: './create-call.component.html',
    styleUrls: ['./create-call.component.scss'],
    standalone: false
})
export class CreateCallComponent implements OnInit, OnDestroy {
    // VARS ####################################################################################################################

    constructor(
        public dialogRef: MatDialogRef<CreateCallComponent>,
        private usersService: UsersService,
        private callService: CallService,
        private loginService: LoginService,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {}

    openErrorDialog: Subject<string> = new Subject();

    subscriptions = new Subscription();

    LIMIT = 30;

    headerLabel: string;
    footerLabel: string;
    restrictedEdition: boolean;

    minDate: Date;
    maxDate: Date;

    structures: Array<any>;
    selectedStructure: any;

    searchTerm: string;
    // lorsque la recherche est "validée"
    searchTermValidated: string;

    call: any;
    dateCallValue: any;
    startDate: Date;
    hourCall: any;

    currentTab: string;

    learnerGroups: Array<any>;
    currentLearnerGroupNumber: number;
    totalLearnerGroupNumber: number;
    currentPageLearnerGroup: number;

    teacherGroups: Array<any>;
    currentTeacherGroupNumber: number;
    totalTeacherGroupNumber: number;
    currentPageTeacherGroup: number;

    learners: Array<any>;
    currentLearnerNumber: number;
    totalLearnerNumber: number;
    currentPageLearner: number;

    teachers: Array<any>;
    currentTeacherNumber: number;
    totalTeacherNumber: number;
    currentPageTeacher: number;

    invitesString = '';
    invitesArray: Array<string> = [];
    regexEmail = RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$');

    isCreating = false;

    ngOnInit() {
        this.call = {};

        this.minDate = new Date();

        if (this.showGroupTab()) {
            this.currentTab = 'learner-group';
        } else {
            this.currentTab = 'learner';
        }

        this.currentTeacherGroupNumber = 0;
        this.currentLearnerGroupNumber = 0;
        this.currentLearnerNumber = 0;
        this.currentTeacherNumber = 0;

        this.currentPageLearnerGroup = -1;
        this.currentPageTeacherGroup = -1;
        this.currentPageLearner = -1;
        this.currentPageTeacher = -1;

        this.headerLabel =
            this.data.callId && !this.data.duplicate ? 'Modifier le call' : 'Nouveau call';
        this.footerLabel =
            this.data.callId && !this.data.duplicate ? 'Modifier le call' : 'Créer le call';

        this.subscriptions.add(
            this.loginService.updateStructure.subscribe((data) => {
                this.selectedStructure = data;
            })
        );

        this.subscriptions.add(
            this.usersService.getStructures().subscribe(
                (data) => {
                    this.structures = data.data.structures.map((structure: any) => {
                        return {
                            key: structure.id,
                            title: structure.name
                        };
                    });
                    this.structures.unshift({ key: 0, title: 'Administration nationale' });
                    this.learnerGroups = [];
                    this.teacherGroups = [];
                    this.learners = [];
                    this.teachers = [];

                    if (this.loginService.isNationalAdmin()) {
                        this.selectedStructure = undefined;
                    } else {
                        const structure = {
                            id: this.loginService.getUser().localStructure,
                            name: this.loginService.getUser().structureid
                        };
                        this.selectedStructure = structure;
                    }

                    if (this.data.callId) {
                        this.subscriptions.add(
                            this.callService.getCall(this.data.callId).subscribe(
                                (call: any) => {
                                    this.call = call;

                                    if (this.data.duplicate) {
                                        this.data.callId = undefined;
                                        this.call.id = undefined;
                                        const startDate = new Date();
                                        startDate.setSeconds(0);
                                        startDate.setMilliseconds(0);

                                        this.dateCallValue = moment(startDate, 'dd/mm/yyyy');

                                        if (startDate.getMinutes() + 5 < 15) {
                                            startDate.setMinutes(15);
                                        } else if (startDate.getMinutes() + 5 < 30) {
                                            startDate.setMinutes(30);
                                        } else if (startDate.getMinutes() + 5 < 45) {
                                            startDate.setMinutes(45);
                                        } else {
                                            startDate.setMinutes(0);
                                            startDate.setHours(startDate.getHours() + 1);
                                        }
                                        this.initHour(startDate);
                                    } else {
                                        const currentDate = new Date();
                                        const startDate = new Date(this.call.starttime);
                                        this.dateCallValue = moment(startDate, 'dd/mm/yyyy');
                                        this.initHour(startDate);
                                        this.restrictedEdition =
                                            currentDate.getTime() >=
                                            startDate.getTime() - 15 * 60 * 1000;
                                    }
                                    this.refreshData(true);
                                    this.initDuration();

                                    this.initNumberSelected();
                                    this.initUsersSelected();
                                },
                                (error: HttpErrorResponse) => {
                                    this.openErrorDialog.next(error.error.userMessage);
                                }
                            )
                        );
                    } else {
                        this.call.users = [];
                        this.call.groups = [];
                        this.call.connect_by_phone = false;
                        const startDate = new Date();
                        startDate.setSeconds(0);
                        startDate.setMilliseconds(0);

                        this.dateCallValue = moment(startDate, 'dd/mm/yyyy');

                        if (startDate.getMinutes() + 5 < 15) {
                            startDate.setMinutes(15);
                        } else if (startDate.getMinutes() + 5 < 30) {
                            startDate.setMinutes(30);
                        } else if (startDate.getMinutes() + 5 < 45) {
                            startDate.setMinutes(45);
                        } else {
                            startDate.setMinutes(0);
                            startDate.setHours(startDate.getHours() + 1);
                        }
                        this.initHour(startDate);

                        this.call.duration = '02:00';
                        if (this.data.userId) {
                            this.getUser(this.data.userId).subscribe((user) => {
                                this.call.users.push({
                                    id: user.id,
                                    firstname: user.firstname,
                                    lastname: user.lastname.toUpperCase(),
                                    role: 'attendee',
                                    rights: 1,
                                    roles: user.roles
                                });
                                this.refreshData(true);
                                this.initNumberSelected();
                                this.initUsersSelected();
                            });
                        } else if (this.data.groupId) {
                            this.getGroup(this.data.groupId).subscribe((group) => {
                                this.call.groups.push({
                                    id: group.id,
                                    name: group.name,
                                    role: 'attendee',
                                    rights: 1
                                });
                                this.refreshData(true);
                                this.initNumberSelected();
                                this.initUsersSelected();
                            });
                        } else {
                            this.refreshData(true);
                        }
                    }
                },
                (error: HttpErrorResponse) => {
                    this.openErrorDialog.next(error.error.userMessage);
                }
            )
        );
    }

    getUser(userId: any) {
        const user$: Subject<User> = new Subject();
        const timeStamp = Date.now();
        (document.getElementById(
            'header-container'
        ) as HTMLIFrameElement).contentWindow.postMessage(
            { getUser: true, userId, timeStamp },
            '*'
        );
        window.addEventListener('message', (event: MessageEvent) => {
            if (event.data.timeStamp === timeStamp) {
                user$.next(event.data.user);
            }
        });
        return user$.asObservable();
    }

    getGroup(groupId: any) {
        const group$: Subject<Group> = new Subject();
        const timeStamp = Date.now();
        (document.getElementById(
            'header-container'
        ) as HTMLIFrameElement).contentWindow.postMessage(
            { getGroup: true, params: { groupid: groupId }, timeStamp },
            '*'
        );
        window.addEventListener('message', (event: MessageEvent) => {
            if (event.data.timeStamp === timeStamp) {
                group$.next(event.data.group);
            }
        });
        return group$.asObservable();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    //// #########################################################################################################################

    //// INIT ####################################################################################################################

    /**
     *  si en mode édition, initialise l'heure de début du call.
     * @param {Date} startDate la date de début d'un call.
     */
    initHour(startDate: Date): void {
        this.hourCall = '';

        const startHour = startDate.getHours();
        const startMinutes = startDate.getMinutes();

        if (startHour < 10) {
            this.hourCall += '0' + startHour + ':';
        } else {
            this.hourCall += '' + startHour + ':';
        }

        if (startMinutes < 10) {
            this.hourCall += '0' + startMinutes;
        } else {
            this.hourCall += '' + startMinutes;
        }
    }

    /**
     *  si en mode édition, initialise la durée du call.
     */
    initDuration(): void {
        const startHour = Math.trunc(this.call.duration / 3600);
        const startMinutes = Math.trunc(this.call.duration / 60) % 60;

        if (startHour < 10) {
            this.call.duration = '0' + startHour + ':';
        } else {
            this.call.duration = '' + startHour + ':';
        }

        if (startMinutes < 10) {
            this.call.duration += '0' + startMinutes;
        } else {
            this.call.duration += '' + startMinutes;
        }
    }

    /**
     *  si en mode édition, initialise le nombre d'utilisateur/groupe membre du call.
     */
    initNumberSelected(): void {
        for (const i in this.call.users) {
            if (
                (this.call.users[i].roles.learner || this.call.users[i].roles.prospect) &&
                !this.call.users[i].owner
            ) {
                this.currentLearnerNumber++;
            } else if (!this.call.users[i].owner) {
                this.currentTeacherNumber++;
            }
        }

        for (const i in this.call.groups) {
            if (this.call.groups[i].type === 'teacher') {
                this.currentTeacherGroupNumber++;
            } else {
                this.currentLearnerGroupNumber++;
            }
        }
    }

    /**
     *  si en mode edition, initialise l'user selectionne.
     */
    initUsersSelected(): void {
        for (const i in this.call.groups) {
            if (this.call.groups[i]) {
                this.call.groups[i].selected = true;
                if (this.call.groups[i].role === 'moderator') {
                    this.call.groups[i].role = 'moderator';
                } else if (this.call.groups[i].role === 'attendee') {
                    this.call.groups[i].role = 'attendee';
                }
                if (this.call.groups[i].is_teacher) {
                    this.teacherGroups.unshift(this.call.groups[i]);
                } else {
                    this.learnerGroups.unshift(this.call.groups[i]);
                }
            }
        }
        for (const i in this.call.users) {
            if (this.call.users[i].roles.learner || this.call.users[i].roles.prospect) {
                this.call.users[i].selected = true;
                if (this.call.users[i].role === 'moderator') {
                    this.call.users[i].role = 'moderator';
                } else if (this.call.users[i].role === 'attendee') {
                    this.call.users[i].role = 'attendee';
                }
                this.learners.unshift(this.call.users[i]);
            } else if (this.call.users[i].id !== this.call.ownerid) {
                this.call.users[i].selected = true;
                if (this.call.users[i].role === 'moderator') {
                    this.call.users[i].role = 'moderator';
                } else if (this.call.users[i].role === 'attendee') {
                    this.call.users[i].role = 'attendee';
                }
                this.teachers.unshift(this.call.users[i]);
            }
        }
        if (this.call.guests) {
            this.invitesArray = this.call.guests.split(';');
        }
    }

    //// #########################################################################################################################

    //// GETTERS #################################################################################################################

    /**
     *  indique si le menu de sélection de structure doit s'afficher pour l'utilisateur actuel.
     * @returns {Boolean} True si le menu doit s'afficher, false dans le cas contraire.
     */
    isNationalAdmin(): boolean {
        return this.loginService.isNationalAdmin();
    }

    /**
     *  indique si l'onglet passé en paramètre est l'onglet courant.
     * @param {string} tab Le nom d'un onglet de la fenêtre de création de call.
     * @returns {boolean} True si l'onglet passé en paramètre est l'onglet courant, false dans le cas contraire.
     */
    isCurrentTab(tab: string): boolean {
        if (this.currentTab) {
            return this.currentTab.localeCompare(tab) === 0;
        }
    }

    /**
     *  indique si toutes les informations nécéssaires à la création du call sont présentes.
     * @returns {boolean} True si le call peut être crée, false dans le cas contraire.
     */
    canCreateCall(): boolean {
        let duration;

        if (this.call.duration) {
            duration = this.call.duration.split(':');
        }
        return (
            this.call.name !== undefined &&
            this.call.name.length >= 2 &&
            this.dateCallValue !== undefined &&
            this.call.dateCallValue !== '' &&
            this.hourCall !== undefined &&
            this.hourCall !== '' &&
            this.call.duration !== undefined &&
            this.call.duration !== '' &&
            (parseInt(duration[0], 10) > 0 || parseInt(duration[1], 10) >= 5) &&
            this.call.description !== undefined &&
            this.call.description !== '' &&
            !this.isCreating &&
            !this.hasInvalidInviteMail()
        );
    }

    /**
     *  vérifie que la durée du call ne dépasse pas 4h, et passe la durée à 4h si ce n'est pas le cas.
     */
    checkDurationCall() {
        const hours = parseInt(this.call.duration.split(':')[0], 10);

        if (hours >= 4) {
            this.call.duration = '04:00';
        }
    }

    /**
     *  affiche la page des groupes suivante.
     */
    nextPageLearnerGroup(newSearch: boolean) {
        this.currentPageLearnerGroup++;

        const params: any = {};

        if (this.isNationalAdmin()) {
            if (this.selectedStructure) {
                params.structureid = this.selectedStructure.id;
            }
        } else {
            params.structureid = this.loginService.getUser().structureid;
        }
        params.search = this.searchTerm ? this.searchTerm : '';
        params.offset = this.currentPageLearnerGroup * this.LIMIT;
        params.limit = this.LIMIT;
        params.type = 'learner';

        this.subscriptions.add(
            this.usersService.getGroups(params).subscribe(
                (data) => {
                    if (newSearch) {
                        const learnerGroupsTmp = [];
                        for (const i in this.learnerGroups) {
                            if (this.learnerGroups[i].selected === true) {
                                learnerGroupsTmp.push(this.learnerGroups[i]);
                            }
                        }
                        this.learnerGroups = learnerGroupsTmp;
                    }

                    for (const i in data) {
                        if (data[i]) {
                            let found = false;
                            for (const j in this.learnerGroups) {
                                if (data[i].id === this.learnerGroups[j].id) {
                                    found = true;
                                }
                            }
                            if (!found) {
                                this.learnerGroups = this.learnerGroups.concat(data[i]);
                            }
                        }
                    }
                },
                (error: HttpErrorResponse) => {
                    this.openErrorDialog.next(error.error.userMessage);
                }
            )
        );
    }

    /**
     *  affiche la page des groupes suivante.
     */
    nextPageTeacherGroup(newSearch: boolean) {
        this.currentPageTeacherGroup++;

        const params: any = {};

        if (this.isNationalAdmin()) {
            if (this.selectedStructure) {
                params.structureid = this.selectedStructure.id;
            }
        } else {
            params.structureid = this.loginService.getUser().structureid;
        }
        params.search = this.searchTerm ? this.searchTerm : '';
        params.offset = this.currentPageTeacherGroup * this.LIMIT;
        params.limit = this.LIMIT;
        params.type = 'teacher';

        this.subscriptions.add(
            this.usersService.getGroups(params).subscribe(
                (data) => {
                    if (newSearch) {
                        const teacherGroupsTmp = [];
                        for (const i in this.teacherGroups) {
                            if (this.teacherGroups[i].selected === true) {
                                teacherGroupsTmp.push(this.teacherGroups[i]);
                            }
                        }
                        this.teacherGroups = teacherGroupsTmp;
                    }

                    for (const i in data) {
                        if (data[i]) {
                            let found = false;
                            for (const j in this.teacherGroups) {
                                if (data[i].id === this.teacherGroups[j].id) {
                                    found = true;
                                }
                            }
                            if (!found) {
                                this.teacherGroups = this.teacherGroups.concat(data[i]);
                            }
                        }
                    }
                },
                (error: HttpErrorResponse) => {
                    this.openErrorDialog.next(error.error.userMessage);
                }
            )
        );
    }

    /**
     *  affiche la page des apprenants suivante.
     */
    nextPageLearner(newSearch: boolean) {
        this.currentPageLearner++;

        const params: any = {};
        if (this.isNationalAdmin()) {
            if (this.selectedStructure) {
                params.structureid = this.selectedStructure.id;
            }
        } else {
            params.structureid = this.loginService.getUser().structureid;
        }
        params.search = this.searchTerm ? this.searchTerm : '';
        params.role = 'learner|prospect';
        params.offset = this.currentPageLearner * this.LIMIT;
        params.limit = this.LIMIT;

        this.subscriptions.add(
            this.usersService.getUsers(params).subscribe(
                (data) => {
                    if (this.data.callId) {
                        for (const i in data) {
                            if (data[i].id === this.call.ownerid) {
                                data.splice(i, 1);
                            }
                        }
                    }
                    for (const i in data) {
                        if (data[i].id === this.loginService.getUser().id) {
                            data.splice(i, 1);
                        }
                    }

                    if (newSearch) {
                        const learnersTmp = [];
                        for (const i in this.learners) {
                            if (this.learners[i].selected === true) {
                                learnersTmp.push(this.learners[i]);
                            }
                        }
                        this.learners = learnersTmp;
                    }

                    for (const i in data) {
                        if (data[i]) {
                            let found = false;
                            for (const j in this.learners) {
                                if (data[i].id === this.learners[j].id) {
                                    found = true;
                                }
                            }
                            if (!found) {
                                this.learners = this.learners.concat(data[i]);
                            }
                        }
                    }
                },
                (error: HttpErrorResponse) => {
                    this.openErrorDialog.next(error.error.userMessage);
                }
            )
        );
    }

    /**
     *  affiche la page des formateurs suivante.
     */
    nextPageTeacher(newSearch: boolean) {
        this.currentPageTeacher++;

        const params: any = {};

        if (this.isNationalAdmin()) {
            if (this.selectedStructure) {
                params.structureid = this.selectedStructure.id;
            }
        } else {
            params.structureid = this.loginService.getUser().structureid;
        }
        params.search = this.searchTerm ? this.searchTerm : '';
        params.offset = this.currentPageTeacher * this.LIMIT;
        params.limit = this.LIMIT;
        params.role =
            'internalTeacher|externalTeacher|siteTeacher|corporationTeacher|tutor|localAdmin|nationalAdmin';

        this.subscriptions.add(
            this.usersService.getUsers(params).subscribe(
                (data) => {
                    if (this.data.callId) {
                        for (const i in data) {
                            if (data[i].id === this.call.ownerid) {
                                data.splice(i, 1);
                            }
                        }
                    }
                    for (const i in data) {
                        if (data[i].id === this.loginService.getUser().id) {
                            data.splice(i, 1);
                        }
                    }

                    if (newSearch) {
                        const teachersTmp = [];
                        for (const i in this.teachers) {
                            if (this.teachers[i].selected === true) {
                                teachersTmp.push(this.teachers[i]);
                            }
                        }
                        this.teachers = teachersTmp;
                    }

                    for (const i in data) {
                        if (data[i]) {
                            let found = false;
                            for (const j in this.teachers) {
                                if (data[i].id === this.teachers[j].id) {
                                    found = true;
                                }
                            }
                            if (!found) {
                                this.teachers = this.teachers.concat(data[i]);
                            }
                        }
                    }
                },
                (error: HttpErrorResponse) => {
                    this.openErrorDialog.next(error.error.userMessage);
                }
            )
        );
    }

    /**
     *  récupère le nombre de groupes dans la structure actuelle.
     */
    getLearnerGroupNumber() {
        const params: any = {};

        if (this.isNationalAdmin()) {
            if (this.selectedStructure) {
                params.structureid = this.selectedStructure.id;
            }
        } else {
            params.structureid = this.loginService.getUser().structureid;
        }
        params.search = this.searchTerm ? this.searchTerm : '';
        params.type = 'learner';

        this.subscriptions.add(
            this.usersService.getGroupsCount(params).subscribe((data: any) => {
                this.totalLearnerGroupNumber = data.count;
            })
        );
    }

    getTeacherGroupNumber() {
        const params: any = {};

        if (this.isNationalAdmin()) {
            if (this.selectedStructure) {
                params.structureid = this.selectedStructure.id;
            }
        } else {
            params.structureid = this.loginService.getUser().structureid;
        }
        params.search = this.searchTerm ? this.searchTerm : '';
        params.type = 'teacher';

        this.subscriptions.add(
            this.usersService.getGroupsCount(params).subscribe((data: any) => {
                this.totalTeacherGroupNumber = data.count;
            })
        );
    }

    /**
     *  récupère le nombre d'apprenants dans la structure actuelle.
     */
    getLearnerNumber() {
        const params: any = {};

        if (this.isNationalAdmin()) {
            if (this.selectedStructure) {
                params.structureid = this.selectedStructure.id;
            }
        } else {
            params.structureid = this.loginService.getUser().structureid;
        }
        params.role = 'learner|prospect';
        params.search = this.searchTerm ? this.searchTerm : '';

        this.subscriptions.add(
            this.usersService.getUsersCount(params).subscribe((data: any) => {
                this.totalLearnerNumber = data.count;
            })
        );
    }

    /**
     *  récupère le nombre de formateurs dans la structure actuelle.
     */
    getTeacherNumber() {
        const params: any = {};

        if (this.isNationalAdmin()) {
            if (this.selectedStructure) {
                params.structureid = this.selectedStructure.id;
            }
        } else {
            params.structureid = this.loginService.getUser().structureid;
        }
        params.role =
            'internalTeacher|externalTeacher|siteTeacher|corporationTeacher|tutor|localAdmin|nationalAdmin';
        params.search = this.searchTerm ? this.searchTerm : '';

        this.subscriptions.add(
            this.usersService.getUsersCount(params).subscribe((data: any) => {
                this.totalTeacherNumber = data.count;
            })
        );
    }

    /**
     *  recupere le nombre de groupes affiches actuellement.
     * @returns {number} le nombre de groupes.
     */
    getCurrentLearnerGroupNumber(): number {
        if (this.learnerGroups) {
            return this.learnerGroups.filter((l) => l.selected).length;
        }
        return 0;
    }

    getCurrentTeacherGroupNumber(): number {
        if (this.teacherGroups) {
            return this.teacherGroups.filter((l) => l.selected).length;
        }
        return 0;
    }

    getCurrentLearnerNumber(): number {
        if (this.learners) {
            return this.learners.filter((l) => l.selected).length;
        }
        return 0;
    }

    getCurrentTeacherNumber(): number {
        if (this.teachers) {
            return this.teachers.filter((l) => l.selected).length;
        }
        return 0;
    }

    getCurrentInviteNumber(): number {
        return this.invitesArray.length;
    }

    /**
     * retourne le nombre total de groupes sélectionnés, y compris ceux qui ne sont pas visibles dans le cas d'une recherche
     */
    getTotalCurrentLearnerGroupNumber(): number {
        if (this.learnerGroups !== undefined) {
            return this.learnerGroups.filter((g) => g.selected).length;
        } else {
            return 0;
        }
    }

    getTotalCurrentTeacherGroupNumber(): number {
        if (this.teacherGroups !== undefined) {
            return this.teacherGroups.filter((g) => g.selected).length;
        } else {
            return 0;
        }
    }

    getTotalCurrentLearnerNumber(): number {
        if (this.learners !== undefined) {
            return this.learners.filter((l) => l.selected).length;
        } else {
            return 0;
        }
    }

    getTotalCurrentTeacherNumber(): number {
        if (this.teachers !== undefined) {
            return this.teachers.filter((t) => t.selected).length;
        } else {
            return 0;
        }
    }

    /**
     * retourne true si un mail invalide est présent dans invitesArray
     */
    hasInvalidInviteMail(): boolean {
        if (
            (!this.invitesArray || this.invitesArray.length === 0) &&
            this.invitesString.length === 0
        ) {
            return false;
        } else {
            return (
                this.invitesArray.some((email) => !this.regexEmail.test(email)) ||
                (this.invitesString.length > 0 &&
                    // && !this.regexEmail.test(this.invitesString)
                    this.invitesString
                        .toLowerCase()
                        .split(';')
                        .some((email) => !this.regexEmail.test(email)))
            );
        }
    }

    /**
     *  verifie si il faut montrer la categorie groupe.
     * @return {any} retourne faux si l'user est corporationTeacher ou siteTeacher.
     */
    showGroupTab(): any {
        if (this.loginService.getUser()) {
            return !(
                this.loginService.getUser().roles.corporationTeacher ||
                this.loginService.getUser().roles.siteTeacher
            );
        }
    }

    /**
     *  verifie si il faut montrer la categorie formateurs.
     * @return {any} retourne faux si l'user est tutor.
     */
    showTeacherTab(): any {
        if (this.loginService.getUser()) {
            return !this.loginService.getUser().roles.tutor;
        }
    }

    /**
     * vérifie si l'utilisateur a le droit d'inviter des participants externes (par mail)
     */
    showInviteTab(): boolean {
        return (
            this.loginService.getUser().roles.nationalAdmin ||
            this.loginService.getUser().roles.localAdmin ||
            this.loginService.getUser().additionalRoles.externalCallManager
        );
    }

    //// #########################################################################################################################

    //// SETTERS #################################################################################################################

    updateStructure($event: any) {
        setTimeout(() => {
            this.structures = $event;
            if (this.structures.some((s) => s.selected)) {
                for (const i in this.structures) {
                    if (this.structures[i].selected) {
                        this.selectedStructure = {
                            id: this.structures[i].key,
                            name: this.structures[i].title
                        };
                    }
                }
            } else if (this.isNationalAdmin()) {
                this.selectedStructure = undefined;
            }
            this.refreshData(true);
        });
    }

    /**
     *  rafraichit la liste des groupes et utilisateurs.
     * @param {boolean} newSearch si c'est une nouvelle recherche ou non.
     */
    refreshData(newSearch: boolean): void {
        this.currentPageTeacherGroup = -1;
        this.currentPageLearnerGroup = -1;
        this.currentPageLearner = -1;
        this.currentPageTeacher = -1;

        if (
            this.selectedStructure &&
            this.selectedStructure.id !== undefined &&
            this.selectedStructure.id !== 0
        ) {
            this.loginService.updateLastStructure(this.selectedStructure);
        }
        if (this.showGroupTab()) {
            this.nextPageLearnerGroup(newSearch);
            this.getLearnerGroupNumber();
            this.nextPageTeacherGroup(newSearch);
            this.getTeacherGroupNumber();
        }
        this.nextPageLearner(newSearch);
        this.getLearnerNumber();
        this.nextPageTeacher(newSearch);
        this.getTeacherNumber();

        // Activation du pipe/filtrage direct à l'affichage pour les learners sélectionnés
        this.searchTermValidated = this.searchTerm;
    }

    /**
     *  crée/Met à jour le call.
     */
    createCall(): void {
        if (this.canCreateCall()) {
            this.isCreating = true;
            this.call.starttime = moment(this.dateCallValue, 'dd/mm/yyyy').toDate();
            this.call.starttime.setHours(this.hourCall.split(':')[0], this.hourCall.split(':')[1]);
            this.call.starttime = this.call.starttime.toISOString();
            this.call.duration += ':00';
            this.call.duration = this.call.duration.substring(0, 8);
            this.call.groups = [];
            this.call.users = [];
            this.formatMembers();
            if (this.invitesString.length > 0) {
                this.invitesArray.push(this.invitesString.toLowerCase());
            }
            this.call.guests = this.invitesArray.join(';');

            const action = this.data.callId
                ? this.callService.updateCall(this.data.callId, this.call)
                : this.callService.createCall(this.call);
            this.subscriptions.add(
                action.subscribe(
                    (data) => {
                        this.callService.refreshCalls();
                        this.callService.refreshCurrentCall();
                        this.closeDialog();
                    },
                    (error: HttpErrorResponse) => {
                        this.isCreating = false;
                        this.openErrorDialog.next(error.error.userMessage);
                    }
                )
            );
        }
    }

    /**
     *  modifie l'onglet courant de la fenêtre.
     * @param {string} tab Le nom d'un onglet de la fenêtre de création de call.
     */
    switchCurrentTab(tab: string) {
        this.currentTab = tab;
    }

    onInvitesStringChange($event: Event) {
        if (this.invitesString[this.invitesString.length - 1] === ';') {
            this.invitesArray.push(
                ...this.invitesString
                    .substring(0, this.invitesString.length - 1)
                    .toLowerCase()
                    .split(';')
                    .map((str) => str.trim())
            );
            setTimeout(() => {
                this.invitesString = '';
            }, 0);
        } else {
            const newMails = this.invitesString
                .toLowerCase()
                .split(';')
                .map((str) => str.trim());
            this.invitesString = newMails[newMails.length - 1];
            newMails.pop();
            this.invitesArray.push(...newMails);
        }
        this.invitesArray = [...new Set(this.invitesArray)];
    }

    removeEmail(index: number) {
        this.invitesArray.splice(index, 1);
    }

    focusInInput(): void {
        document.getElementById('invite-mails_input').focus();
    }

    onBackSpacePress(): void {
        if (this.invitesString.length === 0) {
            this.invitesArray.pop();
        }
    }

    toggleConnectByPhone() {
        if (this.canToggleConnectByPhone()) {
            this.call.connect_by_phone = !this.call.connect_by_phone;
        }
    }

    canToggleConnectByPhone() {
        return (
            !this.call.id ||
            new Date(this.call.starttime).getTime() - new Date().getTime() > 3600 * 1000
        );
    }

    /**
     *  parcourt et formate les tableaux de donnees contenant les groupes/apprenants/tuteurs pour l'application.
     */
    formatMembers(): void {
        for (const i in this.learnerGroups) {
            if (this.learnerGroups[i].selected) {
                let group: Group;
                if (
                    this.learnerGroups[i].role === 'attendee' ||
                    this.learnerGroups[i].role === undefined
                ) {
                    group = {
                        id: this.learnerGroups[i].id,
                        role: 'attendee'
                    };
                } else if (this.learnerGroups[i].role === 'moderator') {
                    group = {
                        id: this.learnerGroups[i].id,
                        role: 'moderator'
                    };
                }
                this.call.groups.push(group);
            }
        }

        for (const i in this.teacherGroups) {
            if (this.teacherGroups[i].selected) {
                let group: Group;
                if (
                    this.teacherGroups[i].role === 'attendee' ||
                    this.teacherGroups[i].role === undefined
                ) {
                    group = {
                        id: this.teacherGroups[i].id,
                        role: 'attendee'
                    };
                } else if (this.teacherGroups[i].role === 'moderator') {
                    group = {
                        id: this.teacherGroups[i].id,
                        role: 'moderator'
                    };
                }
                this.call.groups.push(group);
            }
        }

        for (const i in this.learners) {
            if (this.learners[i].selected) {
                let learner: User;
                if (this.learners[i].role === 'attendee' || this.learners[i].role === undefined) {
                    learner = {
                        id: this.learners[i].id,
                        role: 'attendee'
                    };
                } else if (this.learners[i].role === 'moderator') {
                    learner = {
                        id: this.learners[i].id,
                        role: 'moderator'
                    };
                }
                this.call.users.push(learner);
            }
        }

        for (const i in this.teachers) {
            if (this.teachers[i].selected) {
                let teacher: User;
                if (this.teachers[i].role === 'attendee' || this.teachers[i].role === undefined) {
                    teacher = {
                        id: this.teachers[i].id,
                        role: 'attendee'
                    };
                } else if (this.teachers[i].role === 'moderator') {
                    teacher = {
                        id: this.teachers[i].id,
                        role: 'moderator'
                    };
                }
                this.call.users.push(teacher);
            }
        }
    }

    /**
     *  ferme la fenêtre de création de call
     */
    closeDialog(): void {
        this.dialogRef.close();
    }

    //// #########################################################################################################################
}
