import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription } from 'rxjs';

import { DialogService } from '../../services/dialog.service';
import { CallService } from '../../services/call.service';
import { LoginService } from '../../services/login.service';

import { DeviceDetectorService } from 'ngx-device-detector';

import { User } from '../../structures/user';
import { Group } from '../../structures/group';
import { DomSanitizer } from '@angular/platform-browser';

/**
 *  composant gérant l'affichage de la vue principale
 */
@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    standalone: false
})
export class MainComponent implements OnInit, OnDestroy {
    /**
     * @param {any} currentCall le call recu en input.
     * @param {any} history l'hitorique du call.
     * @param {any} call contenant un call.
     * @param {number} timerEnabledRefresh timer qui permet de compter le delai pour les refresh.
     * @param {any} interval un interval utile lors de l'init d'un call.
     * @param {Boolean} joined si un call est joint ou non.
     * @param {Boolean} recording si on enregistre un call ou non.
     */
    @Input() history: any;

    subscriptions = new Subscription();

    call: any;
    guests: Array<any>;

    timerEnabledRefresh: number;
    interval: any;

    joined: boolean;
    recording: boolean;

    constructor(
        private callService: CallService,
        private dialogService: DialogService,
        private deviceDetectorService: DeviceDetectorService,
        private loginService: LoginService,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer
    ) {}

    /**
     *  initialise le call.
     * @param {any} data Un objet contenant le call.
     */
    initCall(data: any) {
        this.call = data;
        this.call.description = this.sanitizer.bypassSecurityTrustHtml(
            this.call.description.split('\n').join('<br/>')
        );
        this.joined = false;
        this.recording = false;
        this.guests = [];
        if (this.call.guests.length > 0) {
            this.guests = this.call.guests.split(';');
        }
        clearInterval(this.interval);
        this.interval = setInterval(() => {
            this.timerEnabledRefresh--;
            if (this.timerEnabledRefresh <= 0) {
                clearInterval(this.interval);
            }
        }, 1000);

        for (const i in this.call.connected) {
            if (this.loginService.getUser().id === parseInt(this.call.connected[i], 10)) {
                this.joined = true;
            }
        }

        this.call.endtime = new Date(this.call.starttime);
        this.call.starttime = new Date(this.call.starttime);
        this.call.endtime.setSeconds(this.call.endtime.getSeconds() + this.call.duration);
    }

    /**
     *  indique l'état du call.
     * @returns {string} Renvoie une chaîne de caractère indiquant l'état actuel du call.
     */
    getCallState(): string {
        const currentDate = new Date();
        if (!this.call) {
            return;
        }

        if (this.call.status === 'closed') {
            return 'ended';
        }
        if (this.joined === true) {
            return 'joined';
        } else if (
            this.loginService.getUser().id === this.call.ownerid &&
            currentDate.getTime() < this.call.starttime.getTime() - 30 * 60 * 1000
        ) {
            return 'waiting';
        } else if (
            this.loginService.getUser().id !== this.call.ownerid &&
            currentDate.getTime() < this.call.starttime.getTime() - 15 * 60 * 1000
        ) {
            return 'waiting';
        } else {
            return 'started';
        }
    }

    getMembersCounters(): string {
        return `<strong>${this.call.users.length}</strong> participant${
            this.call.users.length > 1 ? 's' : ''
        }, <strong>${this.call.groups.length}</strong> groupe${
            this.call.groups.length > 1 ? 's' : ''
        } et </span><strong>${this.guests.length}</strong> invité${
            this.guests.length > 1 ? 's' : ''
        }.`;
    }

    /**
     *  ouvre la fenêtre du call dans un nouvel onglet.
     */
    joinCall() {
        // HOTFIX#288233

        // if (this.getCallState() === 'started' && this.recording) {

        if (
            (this.getCallState() === 'started' || this.getCallState() === 'joined') &&
            this.recording
        ) {
            // END HOTFIX#288233
            if (
                this.deviceDetectorService.getDeviceInfo().browser === 'ie' ||
                this.deviceDetectorService.getDeviceInfo().browser === 'ms-edge'
            ) {
                this.call.url += '?clientType=flash';
            }
            window.open(this.call.url, '_blank');
            this.joined = true;
        }
    }

    getOrderedUsers() {
        const tmp: Array<User> = Array.from(this.call.users);
        const orderedUsers = [
            tmp.splice(
                tmp.findIndex((user) => user.owner),
                1
            )[0]
        ];
        return orderedUsers.concat(
            tmp.sort((user1: User, user2: User) => {
                if (user1.lastname.toLowerCase() < user2.lastname.toLowerCase()) {
                    return -1;
                } else if (user1.lastname.toLowerCase() > user2.lastname.toLowerCase()) {
                    return 1;
                } else {
                    return user1.firstname.toLowerCase() > user2.firstname.toLowerCase() ? 1 : -1;
                }
            })
        );
    }

    getOrderedGroups() {
        return this.call.groups.sort((group1: Group, group2: Group) => {
            return group1.name.toLowerCase() > group2.name.toLowerCase() ? 1 : -1;
        });
    }

    getOrderedGuests() {
        return this.guests.sort((email1: string, email2: string) => {
            return email1.toLowerCase() > email2.toLowerCase() ? 1 : -1;
        });
    }

    /**
     *  compapitibilite pour ie/edge des warning.
     */
    showFlashWarning() {
        if (
            this.deviceDetectorService.getDeviceInfo().browser === 'ie' ||
            this.deviceDetectorService.getDeviceInfo().browser === 'ms-edge'
        ) {
            return true;
        } else {
            return false;
        }
    }

    /**
     *  rafraichit le call actuel.
     */
    refreshCall() {
        if (this.timerEnabledRefresh <= 0) {
            this.timerEnabledRefresh = 5;
            this.callService.refreshCurrentCall();
        }
    }

    /**
     *  supprime un enregistrement de call.
     * @param record l'engistrement en question.
     */
    deleteRecording(record: any) {
        record = null;
        return;
    }

    /**
     *  lance l'enregistrement d'un call.
     * @param record l'enregistrement en question.
     */
    launchRecording(record: any) {
        if (record.url !== undefined && record.url !== '') {
            window.open(record.url, '_blank');
        }
    }

    getRecordings() {
        return this.call.recordings.filter((recording) => recording.url !== '');
    }

    ngOnInit() {
        this.joined = false;
        this.recording = false;
        this.timerEnabledRefresh = 0;
        const enddate = new Date();
        const startdate = new Date();
        this.route.paramMap.subscribe((params: ParamMap) => {
            if (+params.get('id')) {
                this.callService.getCall(+params.get('id')).subscribe((data: any) => {
                    this.initCall(data);
                });
            }
        });

        this.subscriptions.add(
            this.callService.refreshCall.subscribe(() => {
                if (this.call) {
                    this.subscriptions.add(
                        this.callService.getCall(this.call.id).subscribe(
                            (data) => {
                                this.initCall(data);
                            },
                            (error: HttpErrorResponse) => {
                                this.dialogService.openErrorDialog(error.error.userMessage);
                            }
                        )
                    );
                }
            })
        );

        this.subscriptions.add(
            this.callService.resetCall.subscribe(() => {
                this.call = undefined;
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
