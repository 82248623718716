<div class="header">
    <span class="icon-easi-call-line"></span>
    <span class="label">{{ headerLabel }}</span>
    <span class="icon-close" (click)="closeDialog()"></span>
</div>
<mat-dialog-content class="content">
    <mat-form-field>
        <div class="name">
            <mat-label class="label">Nom*</mat-label>
            <input
                matInput
                class="value"
                type="text"
                placeholder="Nom du call (2 caractères min)"
                [(ngModel)]="call.name"
                [disabled]="restrictedEdition"
            />
        </div>
        <div class="planning">
            <div class="date">
                <div class="label">Date*</div>
                <input
                    type="text"
                    class="date-selector"
                    placeholder="Date"
                    [matDatepicker]="dateCall"
                    [(ngModel)]="dateCallValue"
                    [disabled]="restrictedEdition"
                    [min]="minDate"
                />
                <mat-datepicker-toggle
                    class="date-toggle"
                    matSuffix
                    [for]="dateCall"
                ></mat-datepicker-toggle>
                <mat-datepicker #dateCall></mat-datepicker>
            </div>
            <div class="hour">
                <div class="label">Heure*</div>
                <input
                    matInput
                    class="value"
                    type="time"
                    placeholder="Heure"
                    step="300"
                    [(ngModel)]="hourCall"
                    [disabled]="restrictedEdition"
                />
            </div>
            <div class="duration">
                <div class="label">Durée* (4h maximum)</div>
                <input
                    matInput
                    list="duration"
                    class="value"
                    type="time"
                    min="00:05"
                    max="04:00"
                    step="300"
                    placeholder="Durée"
                    [(ngModel)]="call.duration"
                    [disabled]="restrictedEdition"
                    (change)="checkDurationCall()"
                />
                <datalist id="duration">
                    <option value="00:15"></option>
                    <option value="00:30"></option>
                    <option value="00:45"></option>
                    <option value="01:00"></option>
                    <option value="01:15"></option>
                    <option value="01:30"></option>
                    <option value="01:45"></option>
                    <option value="02:00"></option>
                    <option value="02:15"></option>
                    <option value="02:30"></option>
                    <option value="02:45"></option>
                    <option value="03:00"></option>
                    <option value="03:15"></option>
                    <option value="03:30"></option>
                    <option value="03:45"></option>
                    <option value="04:00"></option>
                </datalist>
            </div>
        </div>
        <div class="agenda">
            <div class="label">Ordre du jour*</div>
            <textarea
                class="value"
                placeholder="Ordre du jour"
                [(ngModel)]="call.description"
                [disabled]="restrictedEdition"
            ></textarea>
        </div>
        <div class="phone" [ngClass]="{ disabled: !canToggleConnectByPhone() }">
            <div
                class="icon"
                [ngClass]="{
                    'icon-select': !call.connect_by_phone,
                    'icon-selected': call.connect_by_phone
                }"
                (click)="toggleConnectByPhone()"
            ></div>
            Accepter les connexions par téléphone
        </div>
    </mat-form-field>
    <div class="separator"></div>
    <div class="members">
        <div class="label-and-filters">
            <div class="label">Participants</div>
            <div class="structure-container" *ngIf="isNationalAdmin() && structures">
                <lib-uimm-dropdown
                    [(items)]="structures"
                    (itemsChange)="updateStructure($event)"
                    [label]="'Toutes les structures'"
                    [labelWidth]="'175px'"
                    [maxWidth]="500"
                    [maxHeight]="250"
                    [design]="'boxes'"
                    [headers]="{ search: true }"
                ></lib-uimm-dropdown>
            </div>
            <div class="search-container">
                <div class="search-input-container">
                    <input
                        class="input-container"
                        type="text"
                        placeholder="Chercher..."
                        [(ngModel)]="searchTerm"
                        (keyup.enter)="refreshData(true)"
                    />
                    <span class="icon-search" (click)="refreshData(true)"></span>
                </div>
            </div>
        </div>
        <div class="top">
            <div class="tab-switch">
                <div
                    class="learner-switch"
                    [ngClass]="{ 'low-opacity': !isCurrentTab('learner') }"
                    (click)="switchCurrentTab('learner')"
                >
                    {{ getCurrentLearnerNumber() }}/{{ totalLearnerNumber }} Apprenants
                </div>
                <div
                    class="learner-group-switch"
                    *ngIf="showGroupTab()"
                    [ngClass]="{ 'low-opacity': !isCurrentTab('learner-group') }"
                    (click)="switchCurrentTab('learner-group')"
                >
                    {{ getCurrentLearnerGroupNumber() }}/{{ totalLearnerGroupNumber }} Groupes
                    d'apprenants
                </div>
                <div
                    class="teacher-switch"
                    *ngIf="showTeacherTab()"
                    [ngClass]="{ 'low-opacity': !isCurrentTab('teacher') }"
                    (click)="switchCurrentTab('teacher')"
                >
                    {{ getCurrentTeacherNumber() }}/{{ totalTeacherNumber }} Formateurs
                </div>
                <div
                    class="teacher-group-switch"
                    *ngIf="showGroupTab() && showTeacherTab()"
                    [ngClass]="{ 'low-opacity': !isCurrentTab('teacher-group') }"
                    (click)="switchCurrentTab('teacher-group')"
                >
                    {{ getCurrentTeacherGroupNumber() }}/{{ totalTeacherGroupNumber }} Groupes de
                    formateurs
                </div>
                <div
                    class="invite-switch"
                    *ngIf="showInviteTab()"
                    [ngClass]="{ 'low-opacity': !isCurrentTab('invite') }"
                    (click)="switchCurrentTab('invite')"
                >
                    {{ getCurrentInviteNumber() }} Invités
                </div>
            </div>
        </div>
        <div
            class="learner-group-container"
            infiniteScroll
            [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="50"
            [scrollWindow]="false"
            (scrolled)="nextPageLearnerGroup(false)"
            *ngIf="isCurrentTab('learner-group')"
        >
            <app-create-call-entry
                *ngFor="let group of learnerGroups"
                [element]="group"
                [(selected)]="group.selected"
                [(role)]="group.role"
            ></app-create-call-entry>
        </div>
        <div
            class="teacher-group-container"
            infiniteScroll
            [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="50"
            [scrollWindow]="false"
            (scrolled)="nextPageTeacherGroup(false)"
            *ngIf="isCurrentTab('teacher-group')"
        >
            <app-create-call-entry
                *ngFor="let group of teacherGroups"
                [element]="group"
                [(selected)]="group.selected"
                [(role)]="group.role"
            ></app-create-call-entry>
        </div>
        <div
            class="learner-container"
            infiniteScroll
            [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="50"
            [scrollWindow]="false"
            (scrolled)="nextPageLearner(false)"
            *ngIf="isCurrentTab('learner')"
        >
            <app-create-call-entry
                *ngFor="let learner of learners"
                [element]="learner"
                [(selected)]="learner.selected"
                [(role)]="learner.role"
            ></app-create-call-entry>
        </div>
        <div
            class="teacher-container"
            infiniteScroll
            [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="50"
            [scrollWindow]="false"
            (scrolled)="nextPageTeacher(false)"
            *ngIf="isCurrentTab('teacher')"
        >
            <app-create-call-entry
                *ngFor="let teacher of teachers"
                [element]="teacher"
                [(selected)]="teacher.selected"
                [(role)]="teacher.role"
            ></app-create-call-entry>
        </div>
        <div class="invite-container" *ngIf="isCurrentTab('invite')" (click)="focusInInput()">
            Adresses mail des invités, séparées par des ;
            <div class="invite-mails">
                <span
                    class="entered-mail"
                    [ngClass]="{ invalid: !regexEmail.test(email) }"
                    *ngFor="let email of invitesArray; let index = index"
                >
                    {{ email }}
                    <span class="icon-close" (click)="removeEmail(index)"></span>
                </span>
                <input
                    type="text"
                    class="invite-mails_input"
                    id="invite-mails_input"
                    [(ngModel)]="invitesString"
                    (ngModelChange)="onInvitesStringChange($event)"
                    (keydown.backspace)="onBackSpacePress()"
                />
            </div>
        </div>
    </div>
    <div class="resume-members">
        Vous avez inscrit <strong>{{ getTotalCurrentLearnerGroupNumber() }}</strong> groupe(s),
        <strong>{{ getTotalCurrentTeacherGroupNumber() }}</strong> groupe(s) de formateurs,
        <strong>{{ getTotalCurrentLearnerNumber() }}</strong> apprenant(s){{
            showInviteTab() ? ',' : ' et'
        }}
        <strong>{{ getTotalCurrentTeacherNumber() }}</strong> formateur(s)
        <span *ngIf="showInviteTab()"
            >et <strong>{{ getCurrentInviteNumber() }}</strong> invité(s)</span
        >
    </div>
    <div class="mandatory-value">* Champs obligatoires</div>
</mat-dialog-content>
<div class="footer">
    <div class="create-button" [ngClass]="{ disabled: !canCreateCall() }" (click)="createCall()">
        {{ footerLabel }}{{ hasInvalidInviteMail() ? " (Liste d'emails invités invalide)" : '' }}
    </div>
</div>
