import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { LoginService } from '../../services/login.service';
import { ConfigService } from '../../services/config.service';

@Component({
    selector: 'app-export-call',
    templateUrl: './export-call.component.html',
    styleUrls: ['./export-call.component.scss'],
    standalone: false
})
export class ExportCallComponent implements OnInit {
    startDateInput: Date;
    endDateInput: Date;

    today: Date;
    minDate: Date;
    startDate: Date;
    endDate: Date;
    structures: Array<any>;

    constructor(
        public dialogRef: MatDialogRef<ExportCallComponent>,
        private loginService: LoginService,
        private configService: ConfigService
    ) {}

    ngOnInit() {
        this.structures = [];
        this.today = new Date();
        this.startDate = new Date();
        this.startDate.setMonth(this.startDate.getMonth() - 1);
        this.startDate.setHours(0, 0, 0, 0);
        this.minDate = new Date(this.startDate);
        this.endDate = new Date();
        this.endDate.setHours(23, 59, 59, 0);
        if (!this.isInternalTeacher()) {
            this.endDate.setFullYear(this.endDate.getFullYear() + 1);
            this.today.setFullYear(this.today.getFullYear() + 1);
        }
        this.loginService.getStructures().subscribe((data: Array<any>) => {
            data.unshift({ name: 'Toutes les structures', id: 0 });
            this.structures = data.map((structure: any) => {
                return {
                    title: structure.name,
                    key: structure.id,
                    selected:
                        parseInt(this.loginService.getUser().structureid, 10) === structure.id ||
                        this.loginService.getLastStructure().id === structure.id
                };
            });
        });
    }

    getMinDate() {
        if (this.loginService.getUser().roles.internalTeacher) {
            return this.minDate;
        } else {
            return undefined;
        }
    }

    isNationalAdmin() {
        return this.loginService.getUser().roles.nationalAdmin;
    }

    isInternalTeacher() {
        return this.loginService.getUser().roles.internalTeacher;
    }

    updateStartDate() {
        this.startDate = new Date(new Date(this.startDateInput).setHours(0, 0, 0));
    }

    updateEndDate() {
        this.endDate = new Date(new Date(this.endDateInput).setHours(23, 59, 59));
    }

    getExportURL() {
        if (!this.isDisabled()) {
            return `${this.configService.getEndPoint()}/reservations/history?startdate=${this.startDate.toISOString()}&endate=${this.endDate.toISOString()}&structureid=${
                this.structures.find((structure) => structure.selected).key
            }`;
        }
    }

    isDisabled() {
        if (this.loginService.getUser().roles.internalTeacher) {
            return this.startDate < this.minDate;
        } else {
            return !this.structures.find((structure) => structure.selected);
        }
    }

    getPlaceholder() {
        if (this.loginService.getUser().roles.internalTeacher) {
            return 'Sélectionner une date valide';
        } else {
            return 'Sélectionner une structure';
        }
    }

    /**
     * Ferme la fenêtre de succes.
     */
    closeDialog() {
        this.dialogRef.close();
    }
}
