import { Component, OnInit, ViewChild, Directive, ElementRef, Inject, HostListener, Input } from '@angular/core';
// import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
    standalone: false
})
export class TooltipComponent {
  @Input() text = '';
  @Input() tooltipColor: string = ('#000');
  @Input() hasArrow = false;
  @Input() tooltipPosition = 'above';
  @Input() tooltipTextColor = '#fff';

  getClasses() {
    return {
      hasArrow: this.hasArrow,
    };
  }

  getStyles() {
    return {
      'background-color': this.tooltipColor,
      'border-color': this.tooltipColor,
      color: this.tooltipTextColor,
    };
  }
}
